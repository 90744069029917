import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { fetchFilesByEmail, checkConvertedFile, checkRunningBatchTranscription, deleteAudio } from "services/api";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AudioPlayer from 'react-h5-audio-player';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Button from '@material-ui/core/Button';
//import { Howl } from 'howler';
import 'react-h5-audio-player/lib/styles.css';
import '../../assets/css/audiolystyles.css';
import { useAuth } from '../../services/authContext';
import { reTranscribe } from "services/api";
import { checkTranscriptionStatus} from "services/api";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);
const CHUNK_SIZE = 1 * 1024 * 1024; // 1MB per chunk (adjust as needed)

export default function TableList() {
  const { user } = useAuth();
  const classes = useStyles();
  const [tableData, setTableData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalUpload, setModalUpload] = useState(false);
  const [modalRetranscribe, setModalRetranscribe] = useState(false);
  //const [modalConfirmation, setModalConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [transcriptionData, setTranscriptionData] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState(""); // State to hold audio URL
  const [isEditing, setIsEditing] = useState(false);
  const [editableText, setEditableText] = useState(transcriptionData);
  const [isSaving, setIsSaving] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(false); // Trigger to re-fetch data
  const [uploadProgress, setUploadProgress] = useState(0);
  const [highlightedSegmentIndex, setHighlightedSegmentIndex] = useState(null);
  const [showProgressBar, setShowProgressBar] = useState(true);
  // const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [audioContext, setAudioContext] = useState(null);
  const [audioBuffer, setAudioBuffer] = useState(null);
  // const [source, setSource] = useState(null);
  const [isUploading, setUpLoading] = useState(false);
  const audioPlayerRef = useRef(null);
  const audioContextRef = useRef(null);
  const dropzoneRef = useRef(null);
  const spanRef = useRef(null);
  const inputRef = useRef(null);
  const [selectedEditText, setEditValue] = useState(null);
  // let audioContext = null;
  //let audioBuffer = null;
  let source = null;
  //const isLoading = useRef(false);
  const LoadingIndicator = () => (
    <div className="uploading-indicator">
      {isUploading && <p style={{ fontWeight: 'bolder', fontSize: '20px' }}>Uploading<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>}
      {isLoading && <p style={{ fontWeight: 'bolder', fontSize: '20px' }}>Loading Audio<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>}
    </div>
  );
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };
  const formatDuration = (durationInSeconds) => {
    // Ensure the duration is a number
    const duration = Number(durationInSeconds);

    if (isNaN(duration) || duration < 0) {
      return 'Invalid duration';
    }

    // Calculate minutes and seconds
    const minutes = Math.floor(duration / 60);
    const seconds = Math.round(duration % 60);

    // Format minutes and seconds with leading zeroes if necessary
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const handleTimeUpdate = () => {
    //console.log('TranscriptionData:', transcriptionData);
    if (audioPlayerRef.current && audioPlayerRef.current.audio && transcriptionData.length > 0) {
      const currentTime = audioPlayerRef.current.audio.current.currentTime;
      const timeBuffer = 0.05; // Allow for a 0.05 second tolerance
      let currentSegment = null;
      // Loop through each transcription segment

      for (let i = 0; i < transcriptionData.length; i++) {
        const words = transcriptionData[i].words;
        // Find the word within the segment that matches the current time
        currentSegment = words.find(
          (word) => currentTime >= word.startTime - timeBuffer && currentTime <= word.endTime + timeBuffer
        );

        // If a matching word is found, break out of the loop
        if (currentSegment) {
          setHighlightedSegmentIndex({ startTime: currentSegment.startTime, endTime: currentSegment.endTime }); // Pass an object // or use another unique identifier like startTime
          break;
        }
      }

      // If no segment is found, reset the highlight
      if (!currentSegment) {
        console.log('No active word found at the current time.');
        setHighlightedSegmentIndex(null);
      }
    }
  };

  const UploadModal = () => {
    setModalUpload(true);
  }
  const handleChange = (event) => {
    console.log('Editable text:', event.target.value);
    setEditableText(event.target.value);
  };



  // When editing is done and focus leaves the input
  const handleDoubleClick = (segmentIndex, wordIndex, startTime, endTime, event) => {
    event.preventDefault();

    const segment = transcriptionData[segmentIndex].words.find(
      (word) => word.startTime === startTime && word.endTime === endTime
    );

    if (segment) {
      setIsEditing({ segmentIndex, wordIndex, startTime, endTime }); // Track both segment and word index
      //console.log('Editable text:',segment.word);
      setEditableText(segment.word); // Set the current word in the editable field
      //setEditableText(transcriptionData[index].text);
     
      setEditValue(segment.word);
    } else {
      console.log('Segment not found');
    }
  };
  const handleBlur = async () => {
    if (isSaving) return; // Prevent multiple calls
    setIsSaving(true);

    if (isEditing !== null && selectedFile && selectedFile.transcriptions.length > 0) {
      const updatedTranscriptionData = [...transcriptionData];
      console.log('Updated Transcription Data:', updatedTranscriptionData);
      // Update the word in the transcription data
      updatedTranscriptionData[isEditing.segmentIndex].words[isEditing.wordIndex].word = editableText;

      // Build the fullRawDetails with speaker and words along with their time segments in the format:
      const fullRawDetails = updatedTranscriptionData
        .map((segment) => {
          // Construct text from words if segment.text is undefined
          const segmentText = segment.text || segment.words.map(word => word.word).join(' ');

          const wordsWithTime = segment.words
            .map((word) => `${word.word} [${word.startTime}s - ${word.endTime}s]`)
            .join(', ');

          return `${segment.speaker}: ${segmentText} (${wordsWithTime})`;
        })
        .join('\n');
      console.log('Full Raw Details:', fullRawDetails);
      // Assuming you have a save function to handle this
      const id = selectedFile.transcriptions[0].id;
      await saveUpdatedTranscription(id, fullRawDetails); // Uncomment your save logic

      // Trigger refresh
      setRefreshTrigger(prev => !prev);

      setIsEditing(null);
    }

    setIsSaving(false); // Reset saving flag
  };

  const saveUpdatedTranscription = async (id, updatedRawDetails) => {
    try {
      const response = await fetch("https://wa-audioly-dev-aueast-002.azurewebsites.net/api/Transcription/UpdateTranscriptionRawDetails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: id,
          rawDetails: updatedRawDetails,
        }),
      });

      if (response.ok) {
        //alert("Transcription updated successfully!");
        setIsEditing(false);
      } else {
        alert("Failed to update transcription.");
      }
    } catch (error) {
      console.error("Error updating transcription:", error);
    }
  };

  const loadAudioFile = async (audioUrl) => {
    // Initialize the audio context if it doesn't exist
    console.log('Loading audio file:', audioUrl);
    if (!audioContextRef.current) {
      try {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        //setAudioContext(audioContext1);
      } catch (e) {
        console.error('Error creating audio context:', e);
        return;
      }
    }
    //console.log('Audio audioBuffer2:', audioBuffer2);
    //isLoading = true;
    try {
      setIsLoading(true); // Show loading indicator
      const response = await fetch(audioUrl);
      const arrayBuffer = await response.arrayBuffer();
      let audioBuffer3 = await audioContextRef.current.decodeAudioData(arrayBuffer);

      console.log('audioBuffer', audioBuffer3);
      setAudioBuffer(audioBuffer3);
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      console.log('audioBuffer', audioBuffer);
      console.log('Audio file successfully loaded.');
      //alert('Audio file successfully loaded.');
    } catch (error) {
      console.error('Error loading audio:', error);
    }
    finally {
      setIsLoading(false); // Loading complete
      //isLoading = false;
    }
  };

  //let sound = null;

  const playAudioForWord = (startTime, endTime) => {
    if (!audioUrl) {
      console.error('Audio URL is not defined.', startTime, endTime);
      return;
    }
    // Load the audio file if not loaded already
    console.log(audioBuffer);
    if (audioBuffer) {
      playSegment(startTime, endTime);
    }
    else {
      alert('Please Note:Audio is not loaded yet. This message will only appear the first time you click the word. Please wait a moment while we prepare the audio for playback.');
      loadAudioFile(audioUrl).then(() => {
        //setIsLoading(true); // Hide loading indicator
        playSegment(startTime, endTime);
      });
    }
  };

  const playSegment = (startTime, endTime) => {
    const duration = endTime - startTime;

    if (!audioBuffer) {
      console.log('Audio not ready yet.');
      return;
    }

    // Stop any previously playing audio
    if (source) {
      source.stop();
    }

    // Create a new audio source from the buffer
    const source1 = audioContextRef.current.createBufferSource();

    source1.buffer = audioBuffer;
    source1.connect(audioContextRef.current.destination);
    console.log('Audio source:', source1);
    // Start the audio from the startTime and play for the word's duration
    source1.start(0, startTime, duration);

    console.log(`Playing from ${startTime} to ${endTime}`);

    // Handle when the playback ends
    source1.onended = () => {
      console.log('Playback ended after word');
    };
    setIsLoading(false);
  };

  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      await handleBlur();
    }
  };

  const handleClick = (event, file) => {
    setAnchorEl(event.currentTarget);
    setSelectedFile(file);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const parseTranscription = (rawDetails) => {
    const segments = [];
    const speakerRegex = /Speaker (\d+):\s*([^[]+)\s*\((.+)\)/g;
    let combinedMatch;
    while ((combinedMatch = speakerRegex.exec(rawDetails)) !== null) {
      const speaker = `Speaker ${combinedMatch[1]}`;
      const speakerText = `speakerText ${combinedMatch[2]}`;  // Get the speaker label
      const segmentDetails = combinedMatch[3]; // Get the word segments inside parentheses
      //console.log('Combined Match:', combinedMatch);
      // eslint-disable-next-line no-useless-escape
      const segmentRegex = /([^,\[\]()]+),?\s*\[(\d+(\.\d+)?)s\s*-\s*(\d+(\.\d+)?)s\]/g;
      let segmentMatch;

      // Create a temporary object to store the speaker and their words
      const speakerSegments = {
        speaker, // Add speaker label once for this group
        speakerText,
        words: [] // Store all words for this speaker
      };

      while ((segmentMatch = segmentRegex.exec(segmentDetails)) !== null) {
        const word = segmentMatch[1].trim(); // Extract the word
        const wordStartTime = parseFloat(segmentMatch[2]);
        const wordEndTime = parseFloat(segmentMatch[4]);

        speakerSegments.words.push({
          word,
          startTime: wordStartTime,
          endTime: wordEndTime,
        });
      }
      // Push the speaker and their words as one entry
      segments.push(speakerSegments);
    }
    return segments;
  };

  const onClickRetranscribe =  async () => 
  {
    if (selectedFile && selectedFile.transcriptions.length > 0)
    {
      const transcriptionId = selectedFile.transcriptions[0].id;
      await reTranscribe(selectedFile.id, transcriptionId);
      
      setModalRetranscribe(true);
      handleClose();


      setRefreshTrigger(prev => !prev);
      
      // retry get status 
      await onCheckTranscriptionStatus(transcriptionId);

      setRefreshTrigger(prev => !prev);

    }
  }
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
const onCheckTranscriptionStatus = async (transcriptionId, retryCount = 10) =>
{
  try
  {
      var response = await checkTranscriptionStatus(transcriptionId);
      console.log(response);
      if(response != 'Succeeded')
      {
        if(retryCount > 0)
        {
          await delay(2000);
          console.log("start delay");
          await onCheckTranscriptionStatus(transcriptionId, retryCount - 1);
        }
      }
  }
  catch(error)
  {
    console.log(error);
  }
}

  const handleTranscriptionClick = () => {
    if (selectedFile && selectedFile.transcriptions.length > 0) {
      const rawDetails = selectedFile.transcriptions[0].rawDetails;
      const parsedSegments = parseTranscription(rawDetails);

      console.log('Parsed segments:', selectedFile.convertedFileUrl);
      setTranscriptionData(parsedSegments);
      //setTranscript(parsedSegments);
      setEditableText("");
      setModalOpen(true);
      // Check if convertedFileUrl is available
      if (selectedFile.convertedFileUrl) {
        setAudioUrl(selectedFile.convertedFileUrl);
        loadAudioFile(selectedFile.url);
      } else {
        console.error('convertedFileUrl is missing in selectedFile');
      }

    } else {
      setTranscriptionData([]);
      setEditableText("No transcription available");
      setModalOpen(true);
    }
    handleClose();
  };
  const handleDeleteAudio = async () => {
    try {
      // Call the delete API
      const id = selectedFile.id;
      const url = selectedFile.url;
      const deleteResponse = await deleteAudio(id, url);
      console.log("ResponseDelete:", deleteResponse);
      alert("Audio deleted successfully!");
      setTableData(prevData => prevData.filter(fileRow => fileRow[6].id !== id));
      setAnchorEl(null);
    } catch (error) {
      console.error("Error deleting audio:", error);
    }
  };
  const handleModalClose = () => {
    if (source) {
      source.stop();  // Stop audio playback if playing
    }
    setModalOpen(false);
    setAudioUrl("");
    console.log('Modal closed');
    setAudioBuffer(null);
    setHighlightedSegmentIndex(null);
  };
  const handleModalUploadClose = () => {
    setModalUpload(false);
    setUploadProgress(0);
  };

  const handleModalRetranscribeClose = () => {
    setModalRetranscribe(false);
  };
  // Fetch files by email using useEffect
  useEffect(() => {
    const fetchData = async () => {
      if (!user || !user.email) {
        console.error("User is not defined or email is missing.");
        return;
      }
      try {
        // Fetch files first
        const files = await fetchFilesByEmail(user.email);
        //console.log("Fetched files:", files); // Log files to inspect structure
        if (!Array.isArray(files)) {
          throw new Error("Expected files to be an array");
        }
        const formattedData = files.map((file) => {
          const transcriptionStatus = file.transcriptions.length > 0
            ? file.transcriptions[0].transcriptionStatus.name
            : "No transcription";
          return [
            file.audioTitleName || "Untitled",
            file.type || "Unknown",
            file.formattedSize || "Unknown size",
            formatDuration(file.duration) || "Unknown duration", // Convert to string
            formatDate(file.uploadedDate) || "Unknown date",
            transcriptionStatus,
            file
          ];
        });
        setTableData(formattedData);
        // Fetch converted file status and batch transcription only after files are fetched
        const convertedFileResponse = await checkConvertedFile(user.email);
        console.log("Converted file response:", convertedFileResponse); // Log response

        const batchTranscriptionResponse = await checkRunningBatchTranscription(user.email);
        console.log("Batch transcription response:", batchTranscriptionResponse); // Log response
        //(files);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const updateInputWidth = () =>
    {
       const input = inputRef.current;
       const span = spanRef.current;

      if (span && input) {
        // Set the input width based on the span width
        console.log(span.offsetWidth);
        input.style.width = `${span.offsetWidth + 10}px`; // Add some padding
      }
    };


    fetchData();
    updateInputWidth();

  }, [refreshTrigger, user, selectedEditText]);

  const uploadFile = async ({ file }) => {
    console.log("Received file:", file); // Add logging to ensure this function is called
    setUpLoading(true);
    const fileSize = file.size;
    const fileName = file.name;
    const totalChunks = Math.ceil(fileSize / CHUNK_SIZE);
    let completedChunks = 0;

    for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
      const start = chunkIndex * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, fileSize);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append("chunk", chunk);
      formData.append("fileName", fileName);
      formData.append("fileSize", fileSize);
      formData.append("chunkIndex", chunkIndex);
      formData.append("totalChunks", totalChunks);

      const response = await fetch("https://wa-audioly-dev-aueast-002.azurewebsites.net/api/Upload/UploadChunk", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        console.error("Failed to upload chunk:", chunkIndex);
        return;
      }
      // Update progress (percentage)
      completedChunks++;
      const progressPercentage = Math.floor((completedChunks / totalChunks) * 100);
      setUploadProgress(progressPercentage);
    }
    // called every time a file's `status` changes

    const completeResponse = await fetch("https://wa-audioly-dev-aueast-002.azurewebsites.net/api/Upload/CompleteUpload", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        FileName: fileName,
        FileSize: fileSize,
        AudioTitleName: "My Audio Title",
        UserEmail: user.email,
      }),
    });

    if (!completeResponse.ok) {
      console.error("Failed to complete upload");
      return;
    }
    const responseData = await completeResponse.json();
    console.log("Complete response:", responseData);
    setUploadProgress(0);
    //alert("File uploaded successfully!");
    return { url: 'https://httpbin.org/post' };
  };
  const handleChangeStatus = ({ meta, file }, status) => {
    console.log('File status:', status, 'Meta:', meta, 'File:', file);
    // To Do: Add logic to handle file status changes and remove the ProgressBar when upload is complete
    if (status === 'done') {
      setUpLoading(false);
      setShowProgressBar(false); // Hide the progress bar when upload is complete
      alert('File uploaded successfully!');
      window.location.reload();
      if (dropzoneRef.current) {
        if (typeof dropzoneRef.current.removeAllFiles === 'function') {
          dropzoneRef.current.removeAllFiles();
        } else {
          removeFile(file);
        }
      }
      // Delay hiding the progress bar to ensure it’s rendered
      setTimeout(() => {
        const progressBar = document.querySelector('.dz-progress');
        if (progressBar) {
          progressBar.style.display = 'none';
        }
      }, 100);
    }
  };

  const removeFile = (file) => {
    const fileElement = document.querySelector(`[data-dz-file="${file.name}"]`);
    if (fileElement) {
      fileElement.remove();
    }
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <h4 className={classes.cardTitleWhite}>Files</h4>
                <p className={classes.cardCategoryWhite}>
                  List of transcriptions
                </p>
              </div>
              <div>
                {/* <button className="uploadButton" onClick={UploadModal}>Add Audio File</button> */}
                <Button className="btnUpload" variant="contained" onClick={UploadModal}>Add Audio File</Button>
              </div>
            </div>

          </CardHeader>
          <CardBody>
            {/* Table for displaying fetched files */}
            <Table
              tableHeaderColor="primary"
              tableHead={[
                "File Title",
                "File Type",
                "File Size",
                "Duration",
                "Upload Date",
                "Status",
                "Action",
              ]}
              tableData={tableData.map((fileRow) => [
                ...fileRow.slice(0, 6), // Extract all fields except the action column
                <IconButton
                  key={fileRow[0]}
                  className={classes.iconButton}
                  onClick={(event) => handleClick(event, fileRow[6])}
                >
                  <MoreVertIcon />
                </IconButton>,
              ])}
            />
            {/* Modal to display transcription */}
            <Modal
              open={modalOpen}
              onClose={handleModalClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div>{isLoading ? (
                <div style={{ marginTop: '20%', marginLeft: '40%', background: '#fff', width: '300px', padding: '10px', borderRadius: '10px' }}>
                  <p style={{ fontWeight: 'bolder', fontSize: '20px', marginRight: '10px' }}>Loading Audio<span className="dot">.</span><span className="dot">.</span><span className="dot">.</span></p>
                </div>
              ) : (
               
                <div className="modalContent">

                  {/* hidden span to be used for matching the input length to the selectedtext */}
                   <span
                    ref={spanRef}
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      whiteSpace: "pre",
                      fontSize: "16px", // Match the input font style
                    }}>
                      {selectedEditText || " "} {/* Ensure there's a space for empty input */}
                    </span>

                  <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Tooltip title="Click on a word to play it, or right click to edit it.">
                      <HelpOutlineIcon style={{ marginRight: '15px', cursor: 'pointer', color: '#828787' }} />
                    </Tooltip>
                    <button onClick={handleModalClose} className="modalButton">
                      X
                    </button>
                  </div>
                  <Typography variant="h6" component="h2">
                    Transcription
                  </Typography>
                  {transcriptionData && transcriptionData.length > 0 ? (
                    <div className="transcriptionContainer">
                      {transcriptionData.map((segment, segmentIndex) => (
                        <div key={segmentIndex} style={{ marginBottom: '5px' }}>
                          {/* Display speaker label in a new line */}
                          <Typography
                            variant="h6"
                            component="p"
                            style={{ marginTop: '5px', fontWeight: 'bold', clear: 'both', fontSize: '15px' }} // Ensure label is separated from previous lines
                          >
                            {segment.speaker + ' :'}
                          </Typography>

                          {/* Display words for the current speaker */}
                          <div style={{ display: 'inline', maxWidth: '700px' }}>
                            {segment.words.map((wordSegment, wordIndex) => (
                              <span key={wordIndex} style={{ display: 'inline-block' }}>
                                {isEditing?.segmentIndex === segmentIndex && isEditing?.wordIndex === wordIndex ? (
                                  <input
                                    ref={inputRef}
                                    value={editableText}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyPress={handleKeyPress}
                                    autoFocus
                                    style={{
                                      display: 'inline-block',
                                      width: "auto", 
                                      transition: "width 0.2s ease",
                                      maxWidth: '100%', // Ensure it doesn't overflow the parent container
                                      cursor: 'pointer',
                                      boxSizing: 'border-box',
                                    }}
                                  />
                                ) : (
                                  <Typography
                                    variant="body2"
                                    component="span" // Change component to span for inline word behavior
                                    onContextMenu={(event) => handleDoubleClick(segmentIndex, wordIndex, wordSegment.startTime, wordSegment.endTime, event)}
                                    style={{ display: 'inline' }}
                                    className={`transcriptionSegment ${highlightedSegmentIndex &&
                                        highlightedSegmentIndex.startTime <= wordSegment.endTime &&
                                        highlightedSegmentIndex.endTime >= wordSegment.startTime
                                        ? 'highlighted'
                                        : ''
                                      }`}
                                    onClick={() => {
                                      // Only play audio if the word is not being edited
                                      if (!(isEditing?.segmentIndex === segmentIndex && isEditing?.wordIndex === wordIndex)) {
                                        playAudioForWord(wordSegment.startTime, wordSegment.endTime);
                                      }
                                    }}
                                  >
                                    {wordSegment.word}&nbsp;
                                  </Typography>
                                )}
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <Typography variant="body2">No transcription available</Typography>
                  )}
                  <div className="audioPlayerContainer">
                  {audioUrl && (
                    <AudioPlayer
                    ref={audioPlayerRef}
                    src={audioUrl}
                    autoPlay={false}
                    controls
                    onPlay={() => {
                      audioPlayerRef.current.audio.current.addEventListener('timeupdate', handleTimeUpdate);
                    }}
                    />
                  )}
                  </div>
                </div>
              )}
              </div>
            </Modal>
            {/* Context Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleTranscriptionClick}>
                View Transcription
              </MenuItem>
              <MenuItem onClick={onClickRetranscribe}>
                Re-Transcribe
              </MenuItem>
              <MenuItem disabled={true}>
                Share Transcription
              </MenuItem>
              <MenuItem disabled={true}>
                Download Transcription
              </MenuItem>
              <MenuItem disabled={true}>
                Summarize Transcription
              </MenuItem>
              <MenuItem onClick={handleDeleteAudio}>
                Delete
              </MenuItem>
              {/* Add more MenuItems for Delete, Play */}
            </Menu>

            {/* Re-Transcribing modal */}
            <Modal
            open={modalRetranscribe}
            onClose={handleModalRetranscribeClose}>
              <div className="modalContentRetranscribe">
                <div>
                  <button onClick={handleModalRetranscribeClose} className="modalReTranscribeButtonClose">X</button>
                </div>
                <div>
                  Selected file is transcribing. This will replace the existing transcription with a new version.
                </div>
              </div>
            </Modal>

            {/* File upload section */}
            <Modal
              open={modalUpload}
              onClose={handleModalUploadClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div>
                <div className="modalContentUpload">
                  <div>
                    <button onClick={handleModalUploadClose} className="modalUploadButtonclose">
                      X
                    </button>
                  </div>
                  <Dropzone
                    getUploadParams={uploadFile}
                    onChangeStatus={handleChangeStatus}
                    accept="audio/*"
                    inputContent="Drag Audio Files or Click to Upload"
                    submitButtonContent="Upload"
                    maxFiles={1}
                    styles={{
                      dropzone: { minHeight: 200, maxHeight: 250 },
                      dropzoneActive: { borderColor: 'green' },
                      progressBar: { display: showProgressBar }
                    }}
                    inputWithFilesContent="Add More Files"
                    previewStyle="inline"
                    autoUpload
                    ref={dropzoneRef}
                  />
                  {/* Custom progress bar */}
                  <div>
                    {isUploading && <LoadingIndicator />}
                  </div>
                  {uploadProgress > 0 && uploadProgress < 100 && (
                    <div style={{ marginTop: '10px' }}>
                      <progress value={uploadProgress} max={100} style={{ width: '100%' }}>
                        {uploadProgress}%
                      </progress>
                      <span>{uploadProgress}%</span>
                    </div>
                  )}
                </div>
              </div>
            </Modal>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
