import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // Replace with correct import
import { useAuth } from '../services/authContext'; // Import useAuth hoo

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(4),
    maxWidth: 400,
    width: '100%',
    textAlign: 'center',
  },
  error: {
    color: 'red',
    marginBottom: theme.spacing(2),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory(); // Use useHistory for navigation
  const { login } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();

    // Dummy authentication logic (replace with your own logic)
    if (username === 'user' && password === 'password') {
      history.push('/admin/dashboard'); // Navigate to the dashboard on success
    } else {
      setError('Invalid username or password');
    }
  };

  const handleGoogleLoginSuccess = (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    console.log('Google User:', decoded);
    // Here, you can handle user details like decoded.email, decoded.name, etc.
    // Store user data in global state
    login({ email: decoded.email, name: decoded.name });
      // Log or use these details as needed

          // Extract user details
    const { name, email } = decoded;
      console.log('Google User:', { name, email });

    // Redirect user after successful login
    history.push('/admin/dashboard');
  };

  const handleGoogleLoginFailure = (error) => {
    console.error('Google Login Failed', error);
    setError('Google login failed. Please try again.');
  };

  return (
    <GoogleOAuthProvider clientId="1024580133305-l0e89pnjqmakb5kdfkhnqforcvjubu7f.apps.googleusercontent.com">
      <Container component="main" className={classes.root}>
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="h5">Login</Typography>
          {error && <Typography className={classes.error}>{error}</Typography>}
          <form className={classes.form} onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Username"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="password"
              label="Password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
          </form>

          {/* Google Login Button */}
          <div className={classes.googleButton}>
            <GoogleLogin
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
              buttonText="Login with Google"
            />
          </div>
        </Paper>
      </Container>
    </GoogleOAuthProvider>
  );
};

export default LoginPage;
