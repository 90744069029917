import axios from "axios";

//production
const apiClient = axios.create({
  baseURL: "https://wa-audioly-dev-aueast-002.azurewebsites.net", // this is my URL
});
//development
// const apiClient = axios.create({
//   baseURL: "https://localhost:7218",
// });

export const fetchData = async () => {
  try {
    const response = await apiClient.get("/data"); // Replace with your API endpoint
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postData = async (data) => {
  try {
    const response = await apiClient.post("/data", data); // Replace with your API endpoint
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const fetchFilesByEmail = async (email) => {
  try {
    // const response = await fetch(
    //   `https://wa-audioly-dev-aueast-002.azurewebsites.net/api/File/GetAllByEmail?email=${email}`
    // );
    const response = await apiClient.get("/api/File/GetAllByEmail", {
      params: { email },
    });
   
    const data = response.data; // Axios automatically parses JSON
    if (!Array.isArray(data)) {
      throw new Error("Expected data to be an array");
    }
    return data;
  } catch (error) {
    console.error("Error in fetchFilesByEmail:", error);
    throw error;
  } // <-- Ensure this closing brace is correctly placed
}; // <-- Ensure this closing brace is correctly placed

// Call CheckConvertedFile API
export const checkConvertedFile = async (email) => {
  try {
    const response = await apiClient.post("/api/Upload/CheckConvertedFile", null, {
      params: { email },
    });
    return response.data;
  } catch (error) {
    console.error("Error in checkConvertedFile:", error);
    throw error;
  }
};

// Call CheckRunningBatchTranscriptionV2 API
export const checkRunningBatchTranscription = async (email) => {
  try {
    const response = await apiClient.post("/api/Transcription/CheckRunningBatchTranscriptionV2", null, {
      params: { email },
    });
    return response.data;
  } catch (error) {
    console.error("Error in checkRunningBatchTranscription:", error);
    throw error;
  }
};
// Delete audio
export const deleteAudio = async (id, url) => {
  try {
    const response = await apiClient.post("/api/File/DeleteAudio", { id, url }); // Replace with your API endpoint
    console.log("response", response);  
    return response;
  } catch (error) {
    console.error("Error deleting audio:", error);
    throw error;
  }
};

export const reTranscribe = async (fileId, transcriptionId) => 
{
  try {
    const response = await apiClient.post("/api/Transcription/ReTranscribe", null,
      {
        params: { fileId, transcriptionId}
      });

      console.log(response.data);

      return response;
  } 
  catch(error)
  {
    console.error("Error retranscribing file:", error);
    throw error;
  }
}

export const checkTranscriptionStatus = async (transcriptionId) =>
{
  try 
  {
    const response = await apiClient.get("/api/transcription/GetTranscriptionStatus",
      {
        params: {transcriptionId}
      });

      return response.data;
  }
  catch(error)
  {
    console.error("Error fetching transcription status:", error);
    throw error;
  }
}