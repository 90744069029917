import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Paper, Typography, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // Replace with correct import
import { useAuth } from '../services/authContext'; // Import useAuth hoo
import { getUser } from '../services/api';
import { useMsal } from '@azure/msal-react';
import { msalLoginRequest } from 'Config';
import { getUserProfilePhoto,loginAccount } from '../services/api';
import { Link } from 'react-router-dom';
// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  paper: {
    padding: theme.spacing(4),
    maxWidth: 400,
    width: '100%',
    textAlign: 'center',
  },
  error: {
    color: 'red',
    marginBottom: theme.spacing(2),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  microsoftDiv:{
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  microsoftButton: {
    display: "inline-flex",
    alignItems: "center",
    border: '1px solid #dadce0',
    boxShadow: 'none !important',
    // width: '25rem',
    color: "white",
    textTransform: "none",
  },
  microsoftLogo: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  microsoftText:
  {
    textTransform: 'none',
    color: '#3c4043',
    fontFamily: `"Google Sans", Arial, sans-serif`,
    fontSize: '14px',
    fontWeight: '500',
  }
}));

const LoginPage = () => {
  const classes = useStyles();
  const history = useHistory(); // Use useHistory for navigation
  const { login } = useAuth();
  const { instance } = useMsal();
  const [email, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleAzureLogin = async() =>
  {
    instance.loginPopup(msalLoginRequest)
    .then(async (response) => {
      console.log(response);
       const name = response.account.name;
       const email = response.account.username;

      const imgUrl = await getUserProfilePhoto(response.accessToken);

      login({ email: email, name: name, profilePicture: imgUrl });

      await getUser(email, name);
      history.push('/admin/dashboard');
    })
    .catch((err) => 
    {
      console.error('Unable to login.', err);
    })
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(''); 
    
    const credentials = {
      email: email,   
      password: password 
    };
    try {
      const userData = await loginAccount(credentials); 
      login({ email: userData.email, name: userData.name, profilePicture: userData.profilePicture }); 
      history.push('/admin/dashboard'); 
    } catch (error) {
      console.error('Login failed:', error);
      setError('Invalid username or password'); 
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const decoded = jwtDecode(credentialResponse.credential);
      console.log('Google User:', decoded);
      login({ email: decoded.email, name: decoded.name,profilePicture:decoded.picture });
      // Extract user details
      const { name, email } = decoded;

      // Call the getUser API
       await getUser(email, name);

      // Redirect user after successful login
      history.push('/admin/dashboard');
    } catch (error) {
      console.error('Google login or API call failed:', error);
      setError('Google login failed. Please try again.');
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error('Google Login Failed', error);
    setError('Google login failed. Please try again.');
  };

  return (
    <div>
    <GoogleOAuthProvider clientId="1024580133305-l0e89pnjqmakb5kdfkhnqforcvjubu7f.apps.googleusercontent.com">
      <Container component="main" className={classes.root}>
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="h5">Login</Typography>
          {error && <Typography className={classes.error}>{error}</Typography>}
          <form className={classes.form} onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Username"
              id="username"
              value={email}
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="password"
              label="Password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
          </form>

    <div>
          {/* Google Login Button */}
          <div className={classes.googleButton}>
            <GoogleLogin
              fullWidth
              onSuccess={handleGoogleLoginSuccess}
              onError={handleGoogleLoginFailure}
              buttonText="Login with Google"
            />
          </div>
          <div className={classes.microsoftDiv}>
            <Button
                className={classes.microsoftButton}
                variant="contained"
                color="primary"
                onClick={handleAzureLogin}
                startIcon={
                  <img
                  src="https://raw.githubusercontent.com/actions/starter-workflows/main/icons/microsoft.svg"
                  alt="Microsoft Logo"
                  className={classes.microsoftLogo}
                />
                }
                style={{ backgroundColor: "#fff", color: 'black' }}>
                <Typography className={classes.microsoftText}>Sign in with Microsoft</Typography>
                </Button>
          </div>
    </div>
    <div>
           {/* Sign Up Prompt */}
           <Typography variant="body2">
            Don&apos;t have an account yet? 
            <Link to="/signup" style={{ marginLeft: '5px', fontWeight: 'bold' }}>Sign Up now</Link>
          </Typography>
    </div>

        </Paper>
      </Container>
    </GoogleOAuthProvider>
    </div>

  );
};

export default LoginPage;
